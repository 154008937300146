<template>
    <div>
        <LiefengContent>
            <template v-slot:title>{{$route.query.name}}</template>
            <template v-slot:toolsbarRight>
                <div class="btn-div">
                    <div class="time">截至时间：{{ $route.query.time || '' }}</div>
                </div>
                <Button type="primary" style="margin-left:20px" @click="exportExcel">导出报表</Button>
            </template>
            <template v-slot:contentArea>
                <div class="city_content" v-if="code == 'CITY_XCX_USER'">
                    <div class="item">本周期新增访问人数:{{cityData.addCycleUser || 0}}</div>
                    <div class="item">本周期内的平均日访问人数:{{cityData.avgCycleUser || 0}}</div>
                    <div class="item">本周期平均日访问人次:{{cityData.avgCycleUv || 0}}</div>
                    <div class="item">本周期访向人数:{{cityData.totalCycleUser || 0}}</div>
                    <div class="item">本周期访问人次:{{cityData.totalCycleUv || 0}}</div>
                    <div class="item">微信用户累计访问平台用户数:{{cityData.totalUser || 0}}</div> 
                </div>
                <LiefengTable
                    :talbeColumns="talbeColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :hidePage="true"
                    ref="table"
                    :summaryData="summaryData"
                    showsummary
                    :showIndex="false"
                ></LiefengTable>
            </template>
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengTable from "@/components/LiefengTable";
export default {
    components:{
        LiefengContent,
        LiefengTable
    },
    data() {
        return {
            talbeColumns:[],
            tableData:[],
            loading:false,
            code:'',
            cityData:{
                addCycleUser:0,
                avgCycleUser:0,
                avgCycleUv:0,
                totalCycleUser:0,
                totalCycleUv:0,
                totalUser:0,
            },
            summaryData: {},
        }
    },
    async created() {
        this.code = this.$route.query.reportCode
        this.talbeColumns = []
        if(this.code == 'CITY_XCX_USER'){
            // 市民政-小程序用户访问报表
            this.talbeColumns = [
                {
                    title: "序号",
                    align: "center",
                    minWidth: 100,
                    key: "sort"
                },
                {
                    title: "时间",
                    align: "center",
                    minWidth: 100,
                    key: "statDate"
                },
                {
                    title:"日新增访问用户",
                    align: "center",
                    minWidth: 100,
                    key: "newUv"
                },
                {
                    title:"日合计访问用户（含老用户）",
                    align: "center",
                    minWidth: 100,
                    key: "totalUv"
                },
                {
                    title:"日合计访问人数",
                    align: "center",
                    minWidth: 100,
                    key: "openNum"
                },
                {
                    title:"截至日累计访问用户（已去重）",
                    align: "center",
                    minWidth: 100,
                    key: "totalUser"
                },
            ]
        }else if(this.code == 'CITY_COMMUNITY_USER'){
            // 市民政-小程序注册用户报表
            this.talbeColumns = [
                {
                    title: "社区名称",
                    align: "center",
                    minWidth: 100,
                    key: "orgName"
                },
                {
                    title: "市级示范社区累计注册用户数",
                    align: "center",
                    minWidth: 100,
                    key: "cityInfo"
                },
                {
                    title: "区级示范社区累计注册用户数",
                    align: "center",
                    minWidth: 100,
                    key: "zoneInfo"
                },
                {
                    title: "街镇示范社区累计注册用户数",
                    align: "center",
                    minWidth: 100,
                    key: "streetInfo"
                },
                {
                    title: "其他社区累计注册用户数",
                    align: "center",
                    minWidth: 100,
                    key: "otherInfo"
                },
                {
                    title: "累计注册用户数",
                    align: "center",
                    minWidth: 100,
                    key: "totalUser"
                },
            ]
        }else if(this.code == 'CITY_ONLINE_INFO_ACTION'){
            // 市民政-示范社区行为数据统计
            this.talbeColumns = [
                {
                    title: "区",
                    align: "center",
                    minWidth: 100,
                    key: "orgName"
                },
                {
                    title: "示范社区数量",
                    align: "center",
                    minWidth: 100,
                    key: "onlineTotal"
                },
                {
                    title: "本周新增信息发布数",
                    align: "center",
                    minWidth: 100,
                    key: "onlineInfoTotalAdd14"
                },
                {
                    title: "当日累计信息发布数",
                    align: "center",
                    minWidth: 100,
                    key: "onlineInfoTotal"
                },
                {
                    title: "本周新增接受服务人次",
                    align: "center",
                    minWidth: 100,
                    key: "onlineUvTotalAdd14"
                },
                {
                    title: "当日累计接受服务人次",
                    align: "center",
                    minWidth: 100,
                    key: "onlineUvTotal"
                },
                {
                    title: "本周新增参与",
                    align: "center",
                    minWidth: 100,
                    key: "onlineJoinTotalAdd14"
                },
                {
                    title: "当日累计参与",
                    align: "center",
                    minWidth: 100,
                    key: "onlineJoinTotal"
                },
            ]
        }else if(this.code == 'CITY_JWH_PERCENT'){
            // 市民政-示范社区行为数据统计
            this.talbeColumns = [
                {
                    title: "区",
                    align: "center",
                    minWidth: 100,
                    key: "zoneName"
                },
                {
                    title: "实有村居委数",
                    align: "center",
                    minWidth: 100,
                    key: "jwhNum"
                },
                {
                    title: "入驻平台的村居委",
                    align: "center",
                    minWidth: 100,
                    key: "jwhByPlatform"
                },
                {
                    title: "入驻平台的村居委- 占比",
                    align: "center",
                    minWidth: 100,
                    key: "platformEnterRate"
                },
                {
                    title: "提供服务的村居委",
                    align: "center",
                    minWidth: 100,
                    key: "jwhPublished"
                },
                {
                    title: "提供服务的村居委- 占比",
                    align: "center",
                    minWidth: 100,
                    key: "jwhPublishedRate"
                },
            ]
        }else if(this.code == 'CITY_ONLINE_COMMUNITY_ACTION'){
             this.talbeColumns = [
                {
                    title: "区",
                    align: "center",
                    minWidth: 100,
                    key: "zoneName"
                },
                {
                    title: "街道",
                    align: "center",
                    minWidth: 100,
                    key: "streetName"
                },
                {
                    title: "社区",
                    align: "center",
                    minWidth: 100,
                    key: "communityName"
                },
                {
                    title: "注册用户数",
                    align: "center",
                    minWidth: 100,
                    key: "registerUser"
                },
                {
                    title: "信息发布数",
                    align: "center",
                    minWidth: 100,
                    key: "informationNum"
                },
                {
                    title: "居民接受信息服务数据",
                    align: "center",
                    minWidth: 100,
                    key: "viewNum"
                },
                {
                    title: "居民参与互动服务统计数据",
                    align: "center",
                    minWidth: 100,
                    key: "userJoinNum"
                },
            ]               
        }
        
        await this.getDetial()
    },
    methods: {
        // 导出当前页数据
        exportExcel(){
                this.$Modal.confirm({
                    title: "温馨提示",
                    content:
                    "<p>您正在导出当前页的数据，是否继续？</p>",
                    onOk: () => {
                        var tHeader = []
                        var filterVal = []
                        if(this.code == 'CITY_XCX_USER'){
                             tHeader = [
                                "序号",
                                "时间",
                                "日新增访问用户",
                                "日合计访问用户（含老用户）",
                                "日合计访问人数",
                                "截至日累计访问用户（已去重）",
                            ];
                             filterVal = [
                                "sort",
                                "statDate",
                                "newUv",
                                "totalUv",
                                "openNum",
                                "totalUser",
                            ];
                        }else if(this.code == 'CITY_COMMUNITY_USER'){
                            tHeader = [
                                "社区名称",
                                "市级示范社区累计注册用户数",
                                "区级示范社区累计注册用户数",
                                "其他社区累计注册用户数",
                                "累计注册用户数"
                            ];
                             filterVal = [
                                "orgName",
                                "cityInfo",
                                "zoneInfo",
                                "otherInfo",
                                "totalUser",
                            ];
                        }else if(this.code == 'CITY_ONLINE_INFO_ACTION'){
                             tHeader = [
                                "区",
                                "示范社区数量",
                                "本周新增信息发布数",
                                "当日累计信息发布数",
                                "本周新增接受服务人次",
                                "当日累计接受服务人次",
                                "本周新增参与",
                                "当日累计参与"
                            ];
                             filterVal = [
                                "orgName",
                                "onlineTotal",
                                "onlineInfoTotalAdd14",
                                "onlineInfoTotal",
                                "onlineUvTotalAdd14",
                                "onlineUvTotal",
                                "onlineJoinTotalAdd14",
                                "onlineJoinTotal"
                            ];

                        }else if(this.code == 'CITY_JWH_PERCENT'){
                            tHeader = [
                                "区",
                                "实有村居委数",
                                "入驻平台的村居委",
                                "入驻平台的村居委- 占比",
                                "提供服务的村居委",
                                "提供服务的村居委- 占比",
                            ];
                             filterVal = [
                                 "orgName",
                                 "jwhNum",
                                 "jwhByPlatform",
                                 "platformEnterRate",
                                 "jwhPublished",
                                 "jwhPublishedRate",
                            ];
                        }else if(this.code == 'CITY_ONLINE_COMMUNITY_ACTION'){
                            tHeader = [
                                "区",
                                "街道",
                                "社区",
                                "注册用户数",
                                "信息发布数",
                                "居民接受信息服务数据",
                                "居民参与互动服务统计数据",
                            ];
                             filterVal = [
                                 "zoneName",
                                 "streetName",
                                 "communityName",
                                 "registerUser",
                                 "informationNum",
                                 "viewNum",
                                 "userJoinNum",
                            ];
                        }
                        let tableData = JSON.parse(JSON.stringify(this.tableData));
                        this.$core.exportExcel(tHeader, filterVal, tableData, `${this.$route.query.name}`);
                    },
                });
           
        },
        // 获取详情接口
        getDetial() {
            this.$get("/statistic/api/symanage/pc/report/selectReportDetail", {
                detailId: this.$route.query.id,
            }).then(res => {
                if(res && res.code == 200){
                    if(this.code == 'CITY_XCX_USER'){
                        // 市民政-小程序用户访问报表
                        this.tableData = res.data.infos
                        this.cityData = {
                            addCycleUser:res.data.addCycleUser,
                            avgCycleUser:res.data.avgCycleUser,
                            avgCycleUv:res.data.avgCycleUv,
                            totalCycleUser:res.data.totalCycleUser,
                            totalCycleUv:res.data.totalCycleUv,
                            totalUser:res.data.totalUser,
                        }
                        var sums = {}
                        let newUv = 0,totalUv=0,openNum=0,totalUser= 0
                        this.tableData.map(item =>{
                            newUv = newUv + item.newUv
                            totalUv = totalUv + item.totalUv
                            openNum = openNum + item.openNum
                            totalUser = totalUser + item.totalUser
                        })
                        sums["sort"] = {
                            key: "sort",
                            value: "合计",
                        }
                        sums["statDate"] = {
                            key: "statDate",
                            value: "",
                        }
                        sums["newUv"] = {
                            key: "newUv",
                            value: newUv,
                        }
                        sums["totalUv"] = {
                            key: "totalUv",
                            value: totalUv,
                        }
                        sums["openNum"] = {
                            key: "openNum",
                            value: openNum,
                        }
                        sums["totalUser"] = {
                            key: "totalUser",
                            value: totalUser,
                        }

                        this.summaryData = sums
                    }else {
                        this.tableData = res.data
                        if(this.code == 'CITY_COMMUNITY_USER'){
                            var sums = {}
                            let cityInfo = 0,zoneInfo=0,otherInfo=0,totalUser= 0
                            this.tableData.map(item =>{
                                cityInfo = cityInfo + item.cityInfo
                                zoneInfo = zoneInfo + item.zoneInfo
                                otherInfo = otherInfo + item.otherInfo
                                totalUser = totalUser + item.totalUser
                            })
                            sums["orgName"] = {
                                key: "orgName",
                                value: "合计",
                            }
                            sums["cityInfo"] = {
                                key: "cityInfo",
                                value: cityInfo,
                            }
                            sums["zoneInfo"] = {
                                key: "zoneInfo",
                                value: zoneInfo,
                            }
                            sums["otherInfo"] = {
                                key: "otherInfo",
                                value: otherInfo,
                            }
                            sums["totalUser"] = {
                                key: "totalUser",
                                value: totalUser,
                            }

                            this.summaryData = sums
                        }else if(this.code == 'CITY_ONLINE_INFO_ACTION'){
                            var sums = {}
                            let onlineTotal = 0,onlineInfoTotalAdd14=0,onlineInfoTotal=0,onlineUvTotalAdd14= 0,onlineUvTotal=0,onlineJoinTotalAdd14=0,onlineJoinTotal=0
                            this.tableData.map(item =>{
                                onlineTotal = onlineTotal + item.onlineTotal
                                onlineInfoTotalAdd14 = onlineInfoTotalAdd14 + item.onlineInfoTotalAdd14
                                onlineInfoTotal = onlineInfoTotal + item.onlineInfoTotal
                                onlineUvTotalAdd14 = onlineUvTotalAdd14 + item.onlineUvTotalAdd14
                                onlineUvTotal = onlineUvTotal + item.onlineUvTotal
                                onlineJoinTotalAdd14 = onlineJoinTotalAdd14 + item.onlineJoinTotalAdd14
                                onlineJoinTotal = onlineJoinTotal + item.onlineJoinTotal
                            })
                            sums["orgName"] = {
                                key: "orgName",
                                value: "合计",
                            }
                            sums["onlineTotal"] = {
                                key: "onlineTotal",
                                value: onlineTotal,
                            }
                            sums["onlineInfoTotalAdd14"] = {
                                key: "onlineInfoTotalAdd14",
                                value: onlineInfoTotalAdd14,
                            }
                            sums["onlineInfoTotal"] = {
                                key: "onlineInfoTotal",
                                value: onlineInfoTotal,
                            }
                            sums["onlineUvTotalAdd14"] = {
                                key: "onlineUvTotalAdd14",
                                value: onlineUvTotalAdd14,
                            }
                            sums["onlineUvTotal"] = {
                                key: "onlineUvTotal",
                                value: onlineUvTotal,
                            }
                            sums["onlineJoinTotalAdd14"] = {
                                key: "onlineJoinTotalAdd14",
                                value: onlineJoinTotalAdd14,
                            }
                            sums["onlineJoinTotal"] = {
                                key: "onlineJoinTotal",
                                value: onlineJoinTotal,
                            }

                            this.summaryData = sums
                        }else if(this.code == 'CITY_JWH_PERCENT'){
                            var sums = {}
                            let jwhNum = 0,jwhByPlatform=0,platformEnterRate=0,jwhPublished= 0,jwhPublishedRate=0
                            this.tableData.map(item =>{
                                jwhNum = jwhNum + item.jwhNum
                                jwhByPlatform = jwhByPlatform + item.jwhByPlatform
                                platformEnterRate = platformEnterRate + item.platformEnterRate
                                jwhPublished = jwhPublished + item.jwhPublished
                                jwhPublishedRate = jwhPublishedRate + item.jwhPublishedRate
                            })
                            sums["zoneName"] = {
                                key: "zoneName",
                                value: "合计",
                            }
                            sums["jwhNum"] = {
                                key: "jwhNum",
                                value: jwhNum,
                            }
                            sums["jwhByPlatform"] = {
                                key: "jwhByPlatform",
                                value: jwhByPlatform,
                            }
                            sums["platformEnterRate"] = {
                                key: "platformEnterRate",
                                value: '',
                            }
                            sums["jwhPublished"] = {
                                key: "jwhPublished",
                                value: jwhPublished,
                            }
                            sums["jwhPublishedRate"] = {
                                key: "jwhPublishedRate",
                                value: '',
                            }

                            this.summaryData = sums
                        }else if(this.code == 'CITY_ONLINE_COMMUNITY_ACTION'){
                            var sums = {}
                            sums["zoneName"] = {
                                key: "zoneName",
                                value: this.tableData[this.tableData.length - 1].mergeName,
                            }
                            sums['streetName'] = {
                                key:'streetName',
                                value:''
                            }
                            sums['communityName'] = {
                                key:'communityName',
                                value:''
                            }
                            sums["registerUser"] = {
                                key: "registerUser",
                                value: this.tableData[this.tableData.length - 1].registerUser,
                            }
                            sums["informationNum"] = {
                                key: "informationNum",
                                value: this.tableData[this.tableData.length - 1].informationNum,
                            }
                            sums["viewNum"] = {
                                key: "viewNum",
                                value: this.tableData[this.tableData.length - 1].viewNum,
                            }
                            sums["userJoinNum"] = {
                                key: "userJoinNum",
                                value:  this.tableData[this.tableData.length - 1].userJoinNum,
                            }
                            this.summaryData = sums
                            if(this.tableData && this.tableData.length){
                                this.tableData.splice(this.tableData.length - 1,1)
                            }
                        }
                    }
                }
            })
        },
    },
}
</script>

<style lang="less" scoped>
.btn-div{
    display: flex;
    line-height: 30px;
    .time{
        margin-left: auto;
    }
}
.city_content{
    display: flex;
    padding: 20px 0;
    font-size: 16px;
    .item{
        width: 15%;
    }
}
</style>